<template>
  <v-container fluid class="py-0">
    <heading>
      <h2>{{ $t("users-.details") }}</h2>
      <p>{{ $t("users-.details-sub-heading") }}</p>
      <template #actions>&nbsp; </template>
    </heading>
    <split-content :hide-auxiliar="!user">
      <template #auxiliar>
        <search-users @selected="userId = $event" />
      </template>
      <validation-observer v-slot="{ valid }">
        <v-form @submit.prevent="submit">
          <v-row justify="center">
            <v-col cols="12">
              <v-row>
                <v-col cols="12" class="py-0">
                  <h4 class="mt-7">{{ $t("users-.data") }}</h4>
                  <p class="mb-5">{{ $t("personal-information-text") }}</p>
                </v-col>
                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('name').toLowerCase()"
                    :rules="{ required: true }"
                  >
                    <v-text-field
                      v-model="request.name"
                      outlined
                      :label="$t('name')"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="request.phone_number"
                    outlined
                    :label="$t('phone-number')"
                  />
                </v-col>
                <v-col cols="12" class="py-0">
                  <h4 class="">{{ $t("system-account") }}</h4>
                  <p class="mb-5">{{ $t("users-.config-credentials") }}</p>
                </v-col>
                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('access-level').toLowerCase()"
                    :rules="{ required: true }"
                  >
                    <v-select
                      v-model="request.level"
                      outlined
                      :items="levels"
                      :label="$t('access-level')"
                      :error-messages="errors"
                    >
                      <template #selection="{ item }">
                        {{ $t(`level-.${item}`) }}
                      </template>
                      <template #item="{ item }">
                        {{ $t(`level-.${item}`) }}
                      </template>
                    </v-select>
                  </validation-provider>
                </v-col>
                <v-col v-if="isClinicDependant(request.level)" cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('clinic').toLowerCase()"
                    :rules="{ required: true }"
                  >
                    <v-select
                      v-model="request.clinic"
                      outlined
                      item-value="id"
                      item-text="name"
                      :items="clinics"
                      :label="$t('clinic')"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('email').toLowerCase()"
                    :rules="{ required: true, email: true }"
                  >
                    <v-text-field
                      v-model="request.email"
                      outlined
                      :label="$t('email')"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col v-if="user == null" cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('password').toLowerCase()"
                    :rules="{ required: user == null }"
                  >
                    <v-text-field
                      v-model="request.password"
                      type="password"
                      outlined
                      :label="$t('password')"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-checkbox
                    v-model="request.active"
                    :label="$t('active')"
                    hide-details
                    class="my-0"
                  />
                </v-col>
                <v-col cols="6">
                  <v-btn
                    elevation="0"
                    block
                    @click.prevent="$router.push({ name: 'users' })"
                  >
                    {{ $t("discard") }}
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn
                    color="primary"
                    type="submit"
                    :disabled="!valid"
                    elevation="0"
                    block
                  >
                    {{ $t("submit") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </split-content>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Heading from "../components/Heading";
import usersMixin from "../../mixins/users";
import users from "../../requests/users";
import clinics from "../../requests/clinics";
import SplitContent from "../components/SplitView";
import SearchUsers from "./Search";

export default {
  mixins: [usersMixin],
  components: {
    Heading,
    SplitContent,
    SearchUsers,
  },
  data: () => ({
    userId: null,
    user: null,
    levels: ["clinic", "manager"],
    clinics: [],
    request: {
      name: "",
      email: "",
      password: "",
      clinic: "",
      level: "clinic",
    },
  }),
  computed: {
    ...mapGetters({
      getUserInfo: "user/userInfo",
    }),
  },
  watch: {
    userId: async function (value) {
      if (value) {
        await this.loadUser(value.id);
      }
    },
  },
  created: async function () {
    if (this.$route.params.id) {
      await this.loadUser(this.$route.params.id);
    }
    const userLevel = (await this.getUserInfo).level;
    if (!this.isClinicDependant(userLevel)) {
      this.levels.push("business");
    }
    if (this.isAdmin(userLevel)) {
      this.levels.push("admin");
    }
    this.clinics = await clinics.simplified();
    if (this.clinics.length > 0) {
      this.request.clinic = this.clinics[0].id;
    }
  },
  methods: {
    loadUser: async function (id) {
      try {
        const user = await users.details(id);
        this.request.name = user.name;
        this.request.email = user.email;
        this.request.level = user.level;
        this.request.active = user.active;
        this.request.clinic = user.clinic ? user.clinic.id : "";
        this.user = user;
      } catch (e) {
        this.$router.push({ name: "business" });
      }
    },
    submit: async function () {
      try {
        let response = null;
        if (!this.user) {
          response = await users.create(this.request);
        } else {
          const request = {};
          let submit = false;
          if (this.request.name != this.user.name) {
            submit = true;
            request.name = this.request.name;
          }
          if (this.request.email != this.user.email) {
            submit = true;
            request.email = this.request.email;
          }
          if (this.request.level != this.user.level) {
            submit = true;
            request.level = this.request.level;
          }
          if (this.request.active != this.user.active) {
            submit = true;
            request.level = this.request.active;
          }
          if (this.request.password != "") {
            submit = true;
            request.password = this.request.password;
          }
          if (submit) {
            response = await users.update(this.user.id, this.request);
          }
        }
        if (response) {
          this.$router.push({ name: "users-details", params: { id: response.id } });
          await this.$store.dispatch(
            "notificator/success",
            this.$t("succeeded_operation")
          );
        }
      } catch (e) {
        console.error(e);
        await this.$store.dispatch("notificator/errorResponse", e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-form {
  max-width: 450px;
}
</style>
